@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;600&display=swap");

:root {
  --off-white: #f4f6fc;
  --dark-blue-primary: #3f64ff;
  --dark-green-primary: #22c55d;
  --dark-blue-secondary: #203a58;
  --dark-gray: #2e3449;
  --text-gray: #9194a3;
  --text-light-blue: #9bafff;
  --text-light-green: #cdf5db;
  --dark-blue-1: #1e2745;
  --dark-blue-2: #182039;
  --dark-blue-3: #151d34;
  --pastel-black: rgb(19, 19, 32);
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--dark-blue-2) !important;
}

.App {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.select-div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.App > .sidebar {
  background: var(--dark-blue-3);
  width: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App > .sidebar > .title {
  font-weight: 600;
  text-align: center;
  padding: 2rem 0;
  font-size: 1.5rem;
  color: var(--off-white);
}

.App > .sidebar > .links {
  padding: 2rem;
}

.App > .sidebar > .links > .socials {
  display: flex;
  padding: 2rem 0 1rem 0;
  gap: 1rem;
  cursor: pointer;
}

.App > .sidebar > .links > .socials > .social {
  height: 1.3rem;
  width: 1.3rem;
  opacity: 0.6;
}

.App > .sidebar > .links > .link {
  padding: 0.3rem 0;
  color: var(--text-gray);
  cursor: pointer;
  width: 100%;
}

.App > .main-content {
  flex-grow: 1;
  overflow-y: scroll;
}

.App > .main-content > .tokens-tab {
  display: flex;
  padding: 3rem 11rem 0 12rem;
  gap: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.App > .main-content > .tokens-tab > .title {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-gray);
}

.App > .main-content > .navbar {
  padding: 2rem 11rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.App > .main-content > .navbar > .header {
  font-size: 1.5rem;
  font-weight: 600;
}

.App > .main-content > .futures-container {
  flex-grow: 1;
}

.connect-web3 {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.connect-web3 > .account-info {
  font-weight: 300;
  color: var(--text-gray);
}

.account-blockie {
  border-radius: 0.5rem;
}

.connect-web3 > .connect-button {
  background: var(--dark-blue-secondary);
  padding: 0.8rem 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--off-white);
  font-size: 0.8rem;
  border-radius: 1rem;
  cursor: pointer;
}

.App > .main-content > .navbar > .tabs {
  display: flex;
  gap: 1rem;
  cursor: pointer;
}

.App > .main-content > .navbar > .tabs > .protocol {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  color: var(--off-white);
}

.App > .main-content > .navbar > .tabs > .protocol > .icon {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}

.App > .main-content > .navbar > .tabs > .active {
  background: var(--dark-gray);
}

.App > .main-content > .futures-container {
  /* background: white; */
  /* border-radius: 2rem; */
  padding: 2rem;
  margin: 0rem 9rem 1rem 9rem;
}

.App > .main-content > .futures-container > .futures {
  border-radius: 2rem;
  padding: 2rem;
  background: var(--dark-blue-1);
  border-radius: 2rem;
  margin-bottom: 2rem;
}

.App > .main-content > .futures-container > .futures > .interactions {
  display: flex;
  justify-content: space-between;
}

.App > .main-content > .futures-container > .futures > .interactions > .token {
  display: flex;
  align-items: center;
  width: 15rem;
  gap: 1rem;
  flex-grow: 1;
}

.token-image {
  height: 2.8rem;
  width: 2.8rem;
  border-radius: 50%;
}

.App
  > .main-content
  > .futures-container
  > .futures
  > .interactions
  > .token
  > .token-name {
  font-size: 1.4rem;
  color: var(--off-white);
}

.App
  > .main-content
  > .futures-container
  > .futures
  > .interactions
  > .token
  > .token-name
  > .status {
  font-size: 0.8rem;
  opacity: 0.8;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.App
  > .main-content
  > .futures-container
  > .futures
  > .interactions
  > .buttons {
  display: flex;
  gap: 2rem;
  height: 100%;
}

.App
  > .main-content
  > .futures-container
  > .futures
  > .interactions
  > .buttons
  > .button {
  color: var(--off-white);
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  width: 7rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.App
  > .main-content
  > .futures-container
  > .futures
  > .interactions
  > .buttons
  > .sub {
  background: var(--dark-blue-primary);
}

.App
  > .main-content
  > .futures-container
  > .futures
  > .interactions
  > .buttons
  > .claim {
  background: var(--dark-green-primary);
}

.deposit-modal {
  padding: 0 !important;
  margin: 0 !important;
  background: var(--dark-blue-primary) !important;
  width: 35rem;
  height: 40rem;
  border-radius: 1rem;
}

.deposit-modal > .header {
  padding: 2rem;
}

.deposit-modal > .header > .title {
  color: var(--off-white);
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.deposit-modal > .header > .sub-title {
  color: var(--text-light-blue);
  font-size: 0.9rem;
}

.deposit-modal > .future-details {
  padding: 0 2rem;
}

.deposit-modal > .future-details > .name {
  color: var(--off-white);
  font-size: 2.5rem;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.deposit-modal > .future-details > .card-container {
  display: flex;
  padding-top: 2rem;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
}

.deposit-modal > .future-details > .card-container > .card {
  background: linear-gradient(#9bafff77, #9bafff0e);
  width: 30%;
  height: 7rem;
  border-radius: 0.5rem;
  /* opacity: 0.4; */
}

.deposit-modal > .future-details > .card-container > .card > .content {
  padding: 1rem;
  opacity: 1 !important;
  color: var(--off-white);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.deposit-modal > .future-details > .card-container > .card > .content > .title {
  font-weight: 600;
  font-size: 0.9rem;
}

.deposit-modal > .future-details > .card-container > .card > .content > .value {
  font-weight: 300;
  font-size: 1.3rem;
}

.deposit-modal > .future-details > .input-amount {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.deposit-modal > .future-details > .claim-buttons {
  display: flex;
  gap: 1rem;
}

.deposit-modal > .future-details > .claim-buttons > .claim-amount {
  width: 100%;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.text-transform-override {
  text-transform: none !important;
}

input {
  padding: 0.8rem 1rem;
  border: none;
  border-radius: 0.5rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.available {
  color: var(--off-white);
  font-size: 0.8rem;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input:focus {
  border: none;
  outline-color: transparent;
  outline-style: none;
}

.deposit-modal > .future-details > .input-amount > .submit-button {
  background: var(--pastel-black);
  color: var(--off-white);
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: uppercase;
  padding: 0.8rem;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
}

.deposit-modal
  > .future-details
  > .claim-buttons
  > .claim-amount
  > .submit-button {
  background: var(--pastel-black);
  color: var(--off-white);
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: uppercase;
  padding: 0.8rem;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
}

.App > .main-content > .futures-container > .futures > .stats {
  padding: 2rem 0 0 0;
  display: flex;
  justify-content: space-between;
}

.App > .main-content > .futures-container > .futures > .stats > .stat > .title {
  margin-bottom: 0.5rem;
  color: var(--text-gray);
}

.App > .main-content > .futures-container > .futures > .stats > .stat > .value {
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: 600;
  color: var(--off-white);
}

.App
  > .main-content
  > .futures-container
  > .futures
  > .stats
  > .stat
  > .status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.App
  > .main-content
  > .futures-container
  > .futures
  > .stats
  > .stat
  > .status
  > .dot {
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
}

.App
  > .main-content
  > .futures-container
  > .futures
  > .stats
  > .stat
  > .value
  > .decimals {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-gray);
}

.App
  > .main-content
  > .futures-container
  > .futures
  > .stats
  > .stat
  > .status
  > .live {
  background: #04d394;
}

.App
  > .main-content
  > .futures-container
  > .futures
  > .stats
  > .stat
  > .status
  > .ended {
  background: red;
}

.right-align {
  text-align: right;
}

.coming-soon {
  color: var(--off-white);
  padding: 2rem 1rem;
  font-size: 2.5rem;
  font-weight: 300;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 10rem;
  height: 60%;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
